<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <hr style="margin:0"/>
              <div class="row">
                <div class="col-md-12">
                  <v-card-title>
                    Inquiries ({{ totalRecords }})
                    <v-spacer></v-spacer>
                    <v-text-field
                        @keydown.enter="searchQueryData"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                  </v-card-title>
                </div>
              </div>
              <v-skeleton-loader
                  :loading="loading"
                  v-if="isLoaded"
                  type="table"
              >
              </v-skeleton-loader>
              <v-data-table
                  show-expand
                  single-expand
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalRecords"
                  :footer-props="{
                  itemsPerPageOptions: rowsPerPageItems,
                  showFirstLastPage: true
                }"
                  :loading="loading"
                  v-show="!isLoaded"
                  mobile-breakpoint="800"
                  class="elevation-0"
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-row>
                      <v-col cols="12">
                        <v-card
                            class="mx-auto"
                            outlined
                            raised
                        >
                          <v-card-text>
                            <v-row>
                              <v-col cols="4" sm="4">
                                <div class="text-truncate">
                                  <h4>Address</h4>
                                  <br>
                                  <p style="white-space: normal" v-html="item.address"></p>
                                </div>
                              </v-col>
                              <v-col cols="4" sm="4">
                                <div class="text-truncate">
                                  <h4>Inquiry Type</h4>
                                  <br>
                                  <p style="white-space: normal" v-html="item.type"></p>
                                </div>
                              </v-col>
                              <v-col cols="4" sm="4">
                                <div class="text-truncate">
                                  <h4>Inquiry Dispute</h4>
                                  <br>
                                  <p style="white-space: normal" v-html="item.dispute"></p>
                                </div>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </td>
                </template>
                <template v-slot:item.name="{ item }">
                  <div class="text-truncate">
                    <p v-html="item.name"></p>
                  </div>
                </template>
                <template v-slot:item.email="{ item }">
                  <div class="text-truncate">
                    <p v-html="item.email"></p>
                  </div>
                </template>
                <template v-slot:item.phone="{ item }">
                  <div class="text-truncate">
                    <p v-html="item.phone"></p>
                  </div>
                </template>
                <template v-slot:item.postal_code="{ item }">
                  <div class="text-truncate">
                    <p v-html="item.postal_code"></p>
                  </div>
                </template>
                <template v-slot:item.message="{ item }">
                  <div class="text-truncate">
                    <p v-html="item.message"></p>
                  </div>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <div class="text-truncate">
                    {{ formatDateAndTime(item.created_at) }}
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="text-truncate">
                    <v-btn
                        color="red"
                        small
                        dark
                        class="mr-2"
                        @click="deleteItem(item)">
                      <v-icon dark small>fas fa-trash</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
              <!-- this dialog is used for both create and update -->
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {bus} from "@/main";
import {
  UPDATE_SETTING,
  CLEAR_SETTING_ERRORS,
  GET_SETTING_DETAILS, INQUIRIES_LIST, DELETE_INQ
} from "@/core/services/store/settings.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";

export default {
  name: "inquiries",
  data() {
    return {
      headers: [
        {
          text: "Type",
          value: "i_type",
          name: "i_type",
          filterable: true,
          sortable: false
        },
        {
          text: "Name",
          value: "name",
          name: "name",
          filterable: false,
          sortable: false
        },
        {
          text: "Email",
          value: "email",
          name: "email",
          filterable: false,
          sortable: false
        },
        {
          text: "Phone Number",
          value: "number",
          name: "number",
          filterable: false,
          sortable: false
        },
        {
          text: "Postal Code",
          value: "postal_code",
          name: "postal_code",
          filterable: false,
          sortable: false
        },
        {
          text: "Created",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true
        },
        {
          text: "Actions",
          value: "actions",
          name: "actions",
          filterable: false,
          sortable: false
        }
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      validationRules: {
        valid: true,
        titleRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 300) || "Must be less than or equal to 300 characters"
        ]
      },
      items: [],
      dialog: false,
      editedItem: {},
    };
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_SETTING_ERRORS);
      if (!val) {
        let that = this;
        setTimeout(function () {
          that.editedItem.description = '';
        }, 500)
      }
      if (!val) this.editedItem = {};
      if (!val) bus.$emit("moduleFilesDeletedByModal");
      !val && this.$refs.form.resetValidation();
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          "&columns[" +
          i +
          "][data]=" +
          this.headers[i].value +
          "&columns[" +
          i +
          "][name]=" +
          this.headers[i].name +
          "&columns[" +
          i +
          "][searchable]=" +
          this.headers[i].filterable +
          "&columns[" +
          i +
          "][orderable]=" +
          this.headers[i].sortable +
          "&columns[" +
          i +
          "][search][value]=" +
          "&columns[" +
          i +
          "][search][regex]=" +
          false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Settings", route: "settings"},
      {title: "Settings"}
    ]);
    if (this.$route.query.hasOwnProperty("addModule")) {
      this.dialog = true;
    }
  },
  methods: {
    refreshData() {
      this.params.sortBy = [];
      this.params.query = "";
      this.getByTypeValue = 'all';
      this.params.sortDesc = "desc";
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise(resolve => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per setting
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params)
            .map(key => {
              return (
                  encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
              );
            })
            .join("&");
        this.getHeaderIndex(params.sortBy[0]);
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 5 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store
            .dispatch(INQUIRIES_LIST, {q: query, columns: this.columns})
            .then(res => {
              this.draw++;
              let items = res.records.data;
              let total = res.records.recordsFiltered;
              this.loading = !this.loading;
              this.isLoaded = !this.isLoaded;
              resolve({
                items,
                total
              });
            });
      });
    },
    reset() {
      this.$refs.form.reset();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(item) {
      this.$store.dispatch(GET_SETTING_DETAILS, item.id).then(data => {
        this.editedItem = data.records || {};
        this.dialog = !this.dialog;
      });
    },
    closeDialog() {
      this.dialog = !this.dialog;
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        let method = UPDATE_SETTING;
        let id = item.id;
        this.$set(item, "image", this.$store.state.uploads.uploadedStateFiles);
        let data = {
          data: item
        };
        if (id) {
          method = UPDATE_SETTING;
        }
        this.$store.dispatch(CLEAR_SETTING_ERRORS);
        this.$store.dispatch(method, {slug: id, data: data.data}).then(() => {
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          this.editedItem = {};
          this.dialog = !this.dialog;
          bus.$emit("moduleFilesDeletedByModal");
          if (this.$route.query.hasOwnProperty("addModule")) {
            window.close();
          }
        });
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?",
        msg: "You want to delete?",
        clr: "red",
        callback: function () {
          this.$store
              .dispatch(DELETE_INQ, {
                'id': item.id,
              })
              .then(() => {
                that.loadData().then(response => {
                  that.items = response.items;
                  that.totalRecords = response.total;
                });
              });
        }
      });
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.settings.errors
    })
  }
};
</script>