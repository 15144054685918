var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',[[_c('v-card',[_c('hr',{staticStyle:{"margin":"0"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-card-title',[_vm._v(" Inquiries ("+_vm._s(_vm.totalRecords)+") "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchQueryData.apply(null, arguments)}}})],1)],1)]),(_vm.isLoaded)?_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"type":"table"}}):_vm._e(),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoaded),expression:"!isLoaded"}],staticClass:"elevation-0",attrs:{"show-expand":"","single-expand":"","headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.totalRecords,"footer-props":{
                itemsPerPageOptions: _vm.rowsPerPageItems,
                showFirstLastPage: true
              },"loading":_vm.loading,"mobile-breakpoint":"800"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":"","raised":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('div',{staticClass:"text-truncate"},[_c('h4',[_vm._v("Address")]),_c('br'),_c('p',{staticStyle:{"white-space":"normal"},domProps:{"innerHTML":_vm._s(item.address)}})])]),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('div',{staticClass:"text-truncate"},[_c('h4',[_vm._v("Inquiry Type")]),_c('br'),_c('p',{staticStyle:{"white-space":"normal"},domProps:{"innerHTML":_vm._s(item.type)}})])]),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('div',{staticClass:"text-truncate"},[_c('h4',[_vm._v("Inquiry Dispute")]),_c('br'),_c('p',{staticStyle:{"white-space":"normal"},domProps:{"innerHTML":_vm._s(item.dispute)}})])])],1)],1)],1)],1)],1)],1)]}},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('p',{domProps:{"innerHTML":_vm._s(item.name)}})])]}},{key:"item.email",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('p',{domProps:{"innerHTML":_vm._s(item.email)}})])]}},{key:"item.phone",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('p',{domProps:{"innerHTML":_vm._s(item.phone)}})])]}},{key:"item.postal_code",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('p',{domProps:{"innerHTML":_vm._s(item.postal_code)}})])]}},{key:"item.message",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('p',{domProps:{"innerHTML":_vm._s(item.message)}})])]}},{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.formatDateAndTime(item.created_at))+" ")])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"red","small":"","dark":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v("fas fa-trash")])],1)],1)]}}])})],1)]],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }